import {CUSTOMER_TOKEN, AUTHENTICATED, CUSTOMER_ID, CUSTOMER_GROUP_ID, COMPANY_NAME} from './customerActionTypes';

export const setAuth = (token) => ({
    type: AUTHENTICATED,
    payload: token
})

export const setToken = (token) => ({
    type: CUSTOMER_TOKEN,
    payload: token
})

export const setID = (id) => ({
    type: CUSTOMER_ID,
    payload: id
})

export const setCustomerGroupID = (id) => ({
    type: CUSTOMER_GROUP_ID,
    payload: `${id}`
})

export const setCompanyName = (companyName) => ({
    type: COMPANY_NAME,
    payload: companyName
})
