export const mainLayoutTranslations: Array<Record<string, string>> = [
    { "search_looking_for": "SearchLookingFor" },
    { "no_matching_items": "NoMatchingItems" },
    { "wishlist": "WishList" },
    { "please_signin_toast": "ToastSignIn" },
    { "footer_text": "FooterText" },
    { "my_account": "MyAccount" },
    { "sign_in": "SignIn" },
    {"this_field_is_required": "RequiredField"},
    { "thank_you": "ThankYou" },
    { "new_customer": "NewCustomer" },
    { "sign_up": "SignUp" },
    { "contact_person": "ContactPerson" },
    { "forgot_password": "ForgotPassword" },
    { "invalid_email_or_password": "InvalidEmailOrPassword" },
    { "email": "Email" },
    { "password": "Password" },
    { "sign_in_with": "SignInWith" },
    { "cart": "Cart" },
    { "cart_empty": "CartEmpty" },
    { "checkout": "Checkout" },
    { "gold_user_group_date": "GoldUserDate" },
    { "gold_user_group_amount": "GoldUserAmount" },
    { "silver_user_group_date": "SilverUserDate" },
    { "silver_user_group_amount": "SilverUserAmount" },
    { "bronze_user_group_date": "BronzeUserDate" },
    { "bronze_user_group_amount": "BronzeUserAmount" },
    {"max_delivery_date": "MaxDeliveryDate"},
    {"user_period": "UserPeriod"},
    {"user_reminder": "UserReminder"},
    {"unlimited_times": "UnlimitedTimes"},
    { "total": "Total" },
    { "tracking": "Tracking" },
    { "contact_with_us": "ContactWithUs" },
    { "subscribe": "Subscribe" },
    { "subscribe_button": "SubscribeButton" },
    { "pages": "Pages" },
    { "blog": "Blog" },
    { "posts": "Posts" },
    { "subscribed": "Subscribed" },
    { "subscribe_message": "SubscribeMessage" },
    {"user_with_this_email_is_subscribed": "UserWithThisEmailIsSubscribed"},
    {"invalid_email_format": "InvalidEmailFormat"},
    {"replace": "Replace"},
    {"add_to_cart": "AddToCartButtonTitle"},
    {"replaced_cross_sell_title": "ReplacedCrossSellTitle"},
    {"upsell_title_less": "UpSellTitleLess"},
    {"upsell_title": "UpSellTitle"},
    {"continue": "Continue"},
    {"no_thanks": "NoThanks"},
    {"cross_sell_title": "CrossSellTitle"},
    {"product_out_of_stock_now": "ToastProductOutOfStock"},
    {"product_not_available": "ProductNotAvailable"},
    {"added_to_cart_toast": "ToastAddToCart"},
    {"gdpr_button": "AcceptCookie"},
    {"footer_menu": "FooterMenu"},
    {"footer_contacts": "FooterContacts"},
    {"removed_from_wishlist_toast": "ToastWishListRemove"},
    {"added_to_wishlist_toast": "ToastWishList"},
    {"our_contacts": "OurContacts"},
    {"wishlist_empty": "WishListEmpty"},
];

export const NotFoundPageTranslations: Array<Record<string, string>> = [
    ...mainLayoutTranslations,
    {"page_not_available": "PageNotAvailable"},
    {"search_or_back": "SearchOrBack"},
    {"back_home": "GoToHome"},
]

export const layoutTranslations: Array<Record<string, string>> = [
    ...mainLayoutTranslations,
    {"home": "Home"},
    {"shopping_cart": "ShoppingCart"},
    {"track_your_order": "TrackYourOrder"},
    {"reset_password": "ResetPassword"},
    {"sorry_nothing_found_for": "NothingFound"},
    {"save": "Save"},
    {"send": "Send"},
    {"add_an_address": "AddAnAddress"},
    {"qty": "Quantity"},
    {"price": "Price"},
    {"product": "Product"},
    {"total_price": "TotalPrice"},
    {"order": "Order"},
    {"reset_password_done": "ResetPasswordDone"},
    {"type_valid_data": "TypeValidData"},
    {"characters_passwords": "CharactersPassword"},
    {"passwords_are_not_the_same": "PasswordsAreNotTheSame"},
    {"in_stock": "InStock"},
    {"out_of_stock": "OutOfStock"},
    {"payment_method": "PaymentMethod"},
    {"no_products_yet": "NoProductsYet"},
    {"try_reset_filters": "TryResetFilters"},
    {"reset": "Reset"},
    {"availability": "Availability"},
    {"shipping_methods": "ShippingMethods"},
    {"billing_address": "BillingAddress"},
    {"discount": "Discount"},
    {"tax": "Tax"},
    {"shipping_address": "ShippingAddress"},
    {"your_order_has_been_received": "OrderHasBeenReceived"},
    {"go_to_homepage": "ToHomePage"},
    {"the_email_field_is_required": "TheEmailFieldIsRequired"},
    {"invalid_email_format": "InvalidEmailFormat"},
    {"the_phone_field_is_required.": "ThePhoneFieldIsRequired"},
    {"phone": "Phone"},
    {"address": "Address"},
    {"city": "City"},
    {"apartment": "Apartment"},
    {"postal_code": "PostalCode"},
    {"current_password": "CurrentPassword"},
    {"new_password": "NewPassword"},
    {"first_name": "FirstName"},
    {"last_name": "LastName"},
    {"comment": "Comment"},
    {"subtotal": "SubTotal"},
    {"shipping": "Shipping"},
    {"the_email_must_be_a_valid_email_address": "TheEmailMustBeAValidEmailAddress"},
    {"email_has_been_sent_successfully": "EmailHasBeenSentSuccessfully"},
    {"confirm_password": "ConfirmPassword"},
    {"read_more": "ReadMore"},
    {"invalid_url_format": "InvalidUrl"},
]

export const HomePageTranslations: Array<Record<string, string>> = [
    ...mainLayoutTranslations,
    {"homepage_title_text": "HomeHeading"},
    {"homepage_intro_text": "HomeIntro"},
    {"availability": "ProductAvailable"},
    {"in_stock": "ProductInStock"},
    {"slideshow_btn": "SliderButtonTitle"},
]

export const StaticPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"new_search": "NewSearch"},
    {"title_one": "TitleOne"},
    {"title_two": "TitleTwo"},
    {"title_tree": "TitleThree"},
    {"message_sent_successfully": "MessageSentSuccessfully"},
    {"track": "Track"},
    {"your_track_number": "TrackYourNumber"},
]

export const AccountTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"personal_information": "PersonalInformation"},
    {"my_orders": "MyOrders"},
    {"sign_out": "SignOut"},
    {"minimum_delivery_days": "MinimumDeliveryDays"},
    {"reorder": "Reorder"},
    {"back_to_list": "BackToList"},
    {"edit_address": "EditAddress"},
    {"add_new": "AddNewAddress"},
    {"the_first_name_field_is_required": "TheFirstNameFieldIsRequired"},
    {"the_last_name_field_is_required.": "TheLastNameFieldIsRequired"},
    {"the_address1_field_is_required.": "TheAddress1FieldIsRequired"},
    {"the_address2_field_is_required.": "TheAddress2FieldIsRequired"},
    {"the_city_field_is_required.": "TheCityFieldIsRequired"},
    {"the_postcode_field_is_required.": "ThePostcodeFieldIsRequired"},
    {"account_updated_successfully": "AccountUpdatedSuccessfully"},
    {"the_date_of_birth_must_be_a_date_before_today": "TheDateOfBirthMustBeADateBeforeToday"},
    {"the_gender_field_is_required": "TheGenderFieldIsRequired"},
    {"change_password": "ChangePassword"},
    {"save_changes": "SaveChanges"},
    {"select_gender": "SelectGender"},
    {"order_id": "OrderId"},
    {"date": "Date"},
    {"status": "Status"},
    {"order_list_empty": "OrderListEmpty"},
    {"we_recommend": "WeRecommend"},
    {"date_of_birth": "DateOfBirth"},
    {"confirm_password": "ConfirmPassword"},
    {"pending": "Pending"},
    {"downloadable_products": "DownloadableProducts"},
    {"remaining_downloads": "RemainingDownloads"},
    {"downloadable_products_list_empty": "DownloadableListEmpty"},
    {"available": "Available"},
    {"name": "Name"},
    {"expired": "Expired"},
    {"download": "Download"},
    {"downloads": "Downloads"},
    {"completed": "Completed"},
    {"reorder": "Reorder"},
    {"canceled": "Canceled"},
    {"processing": "Processing"},
    {"closed": "Closed"},
    {"unlimited_times": "UnlimitedTimes"}
]

export const CategoryPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"sort_by": "SortBy"},
    {"price_low_high": "PriceLowHigh"},
    {"price_high_low": "PriceHighLow"},
    {"default": "Default"},
    {"home": "Home"},
    {"filters": "Filters"},
]

export const CardInnerPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"home": "Home"},
    {"related_products": "RelatedProducts"},
    {"price": "Price"},
    {"quantity_discount": "QuantityDiscount"},
    {"and_more": "AndMore"},
    {"choose_option": "ChooseOption"},
    {"delivery_date": "DeliveryDate"},
    {"delivery_days": "WorkingDay"},
    {"details": "Details"},
    {"sample": "Sample"},
    {"samples": "Samples"},
    {"links": "Links"},
    {"the_links_field_is_required": "LinksRequired"},
]

export const SignPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"account_created_successfully": "AccountCreateSuccess"},
    {"verification_mail_toast": "VerificationMailToast"},
    {"registration": "Registration"},
    {"invalid_url_format": "InvalidUrl"},
    {"use_new_password": "UseNewPassword"},
    {"the_password_field_is_required": "ThePasswordFieldIsRequired"},
    {"the_email_has_already_been_taken.": "TheEmailHasAlreadyBeenTaken"}
]

export const CartPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"delete": "Delete"},
    {"continue_shopping": "ContinueShopping"},
    {"go_to_cart": "GoToCart"},
    {"contact_information": "ContactInformation"},
    {"already_have_an_account": "HaveAccount"},
    {"billing_same_as_shipping": "BillingSameAsShipping"},
    {"save_address": "SaveAddress"},
    {"delivery_days": "WorkingDay"},
    {"i_agree_with": "IAgreeWith"},
    {"pay": "Pay"},
    {"payment_failed_title": "PaymentFailedTitle"},
    {"payment_failed_message": "PaymentFailedMessage"},
    {"payment_failed_message": "PaymentFailedMessage"},
    {"coupon_code_applied_successfully.": "CouponCodeAppliedSuccessfully"},
    {"coupon_code_is_invalid.": "CouponCodeIsInvalid"},
    {"shipping_methods_waiting_note": "ShippingMethodsWaitingNote"},
    {"add_new_address": "NewAddress"},
    {"select_country": "SelectCountry"},
    {"select_state": "SelectState"},
    {"wishlist_empty": "WishListEmpty"},
    {"coupon_code": "CouponCode"},
    {"apply": "Apply"},
    {"remove": "Remove"},
    {"remove_coupon": "RemoveCoupon"},
    {"fee": "Fee"},
    {"this_field_is_required": "RequiredField"},
    {"max_delivery_date": "MaxDeliveryDate"}
]

export const BlogPageTranslations: Array<Record<string, string>> = [
    ...layoutTranslations,
    {"post_category": "PostCategories"},
    {"post_category_all": "PostCategoriesAll"},
    {"tags": "Tags"},
    {"share_on": "PostShare"},
]

