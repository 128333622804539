import {AUTHENTICATED, CUSTOMER_TOKEN, CUSTOMER_ID, CUSTOMER_GROUP_ID, COMPANY_NAME} from './customerActionTypes'

const initialState = {
    authenticated: false,
    token: '',
    customerId: '',
    customerGroupId: '1',
    companyName: ''
};

const customerReducer = (state = initialState, action) => {

    switch (action.type) {


        case AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload
            }

        case CUSTOMER_TOKEN:
            return {
                ...state,
                token: action.payload
            }

        case CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload
            }

        case CUSTOMER_GROUP_ID:
            return {
                ...state,
                customerGroupId: action.payload
            }

        case COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload
            }

        default:
            return state;
    }
};

export default customerReducer;